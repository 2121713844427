import React, { Fragment, useEffect, useState } from 'react';
import Link from 'next/link';
// import StarRatings from 'react-star-ratings';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Modal from "react-responsive-modal";
import { closeIcon, PriceConvertor } from "../../../common_components/CommonFields";
import Image from 'next/image';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { FaClipboard } from 'react-icons/fa';
import share from '../../../public/images/share.png';
import Analytics from '../../../common_components/Common/Analytics';
// import car from '../../../public/images/used-images/nexon.jpg';


export default function HomeUpcomingVehicleCard({ vehicleData, label,subtype, type }) {

  const [totalPrice1, setTotalPrice1] = useState();
  const [totalPrice2, setTotalPrice2] = useState();
  const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL;
  const [openShareModal, setOpenShareModal] = useState("");

  useEffect(() => {
    const value = PriceConvertor(vehicleData.total_price);
    setTotalPrice1(value)
    const value2 = PriceConvertor(vehicleData.total_price);
    setTotalPrice2(value2)
  }, [vehicleData])

  const toggleShareModal = (data) => {
    setOpenShareModal(data._id);
  };
  const HandleEvents = (data) => {
    const Analyticsprops = {
      GA: true,
      title :`${subtype}-Events`, 
      category: `${type === 1? 'Home Page' : 'Vehicles listing page'}-Events`,
      action: `vehicle ${data} `,
      label: `${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title} ${data} clicked!`,
    }
    Analytics(Analyticsprops)
  }
  const handleClipboard = (slug) => {
    navigator.clipboard.writeText(
      `${baseUrl}/product/${slug}`
    );
    toast.success('Link Copied!!!');
    setOpenShareModal('');
  };
  const renderShareModal = () => {
    return (
      <Fragment>
        <Modal
          center
          open={openShareModal ? true : false}
          closeIcon={closeIcon}
          onClose={() => setOpenShareModal('')}
        >
          <div style={{ minWidth: '280px', textAlign: 'center' }}>
            <div className='px-5 py-2 fs-5 fw-bold mb-2'>Share via :</div>
            {vehicleData && vehicleData.companyData && (
              <ul>
                <li>
                  <span className='me-2'>
                    <ReactTooltip id={vehicleData?._id} place='top' effect='solid'>
                      Copy Link
                    </ReactTooltip>
                    <FaClipboard
                      data-tip
                      data-for={vehicleData?._id}
                      style={{ fontSize: '2.3rem', cursor: 'pointer' }}
                      onClick={(e) =>   (vehicleData.slug)}
                    />
                  </span>
                  <span className='me-2'>
                    <FacebookShareButton
                      url={`${baseUrl}/product/${vehicleData.slug}`}
                      quote={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title
                        }`}
                    // hashtag={`#${
                    //   vehicleData.companyData?.vehicle_prefix +vehicleData.title
                    // }`}
                    >
                      <FacebookIcon size={'2.5rem'} round />
                    </FacebookShareButton>
                  </span>
                  <span className='me-2'>
                    <WhatsappShareButton
                      url={`${baseUrl}/product/${vehicleData.slug}`}
                      title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title
                        }`}
                      separator=' :: '
                    >
                      <WhatsappIcon size={'2.5rem'} round />
                    </WhatsappShareButton>
                  </span>
                  <span className='me-2'>
                    <TwitterShareButton
                      url={`${baseUrl}/product/${vehicleData.slug}`}
                      title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title
                        }`}
                    // hashtags={`[#${
                    //   vehicleData.companyData.title.toLowerCase() +
                    //   vehicleData.title.toLowerCase()
                    // }]`}
                    >
                      <TwitterIcon size={'2.5rem'} round />
                    </TwitterShareButton>
                  </span>
                  <span className='me-2'>
                    <TelegramShareButton
                      title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title
                        }`}
                      url={`${baseUrl}/product/${vehicleData.slug}`}
                    >
                      <TelegramIcon size={'2.5rem'} round />
                    </TelegramShareButton>
                  </span>
                  <LinkedinShareButton
                    title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title +' ' + vehicleData.title
                      }`}
                    url={`${baseUrl}/product/${vehicleData.slug}`}
                  >
                    <LinkedinIcon size={'2.5rem'} round />
                  </LinkedinShareButton>
                </li>
              </ul>
            )}
          </div>
        </Modal>
      </Fragment>
    );
  };

// console.log(vehicleData)
  return (
    <div className='new-vehicle-card pb-3 h-100 rounded-2 border home-vehicle-card'>
      <div className='mb-1 px-3 pt-3 pb-2'>
        <span className='new-lbl border'>{label || ''}</span>
        <span
          className='float-end'
          onClick={(e) => { setOpenShareModal(vehicleData._id); HandleEvents('Share') }}
        >
          <Image
            src={share}
            className='c-pointer'
            alt='share'
            width='18px'
            height='18px'
            objectFit="contain"
            loading="lazy"
            onClick={(e) => toggleShareModal(vehicleData)}
          />
        </span>
      </div>
      <Link href={`/product/${vehicleData && vehicleData.slug}`}>
        <a aria-label={`link for ${vehicleData.slug} page`}>
          <Image
            src={vehicleData.image[0]}
            className='px-3 c-pointer vehicle-img'
            alt={`${vehicleData && vehicleData.title}-img`}
            width='300px'
            height='190px'
            loading='lazy'
            sizes="320 640 750"
            layout='responsive'
            objectFit="cover"
            onClick={() => HandleEvents('logo') }
          // style={{ objectFit: 'cover' }}
          />
        </a>
      </Link>
      <hr className='mt-3' style={{ height: '1px' }} />
      <div className='row w-100 mx-0 px-3 pt-2'>
      <Link href={`/product/${vehicleData && vehicleData.slug}`}>
        <a className='p-0' aria-label={`link for ${vehicleData.slug} page`}>
        <h2 className='fs-16 fw-400 mb-0 col-11 px-0 pb-1 d-flex fs-6 align-items-center' onClick={() => HandleEvents('name')}>
          {vehicleData.companyData && vehicleData.companyData?.vehicle_prefix
            ? vehicleData.companyData?.vehicle_prefix
            : vehicleData.companyData?.title}{' '}
          {vehicleData && vehicleData.title}
        </h2>
        </a>
      </Link>
        <div
          className='col-1 pt-1 px-0 text-end'
        // onClick={() => {
        //   isAvailable ? removeVehicle() : saveVehicle();
        // }}
        >
          {/* <i
            className={classNames(
              { 'fas fa-bookmark bookmark-icon-selected': true } 
                   checkSavedData(),}
               {
                 'fas fa-bookmark bookmark-icon-selected':
                   checkSavedData(),
               },
               {
                 'far fa-bookmark bookmark-icon': !checkSavedData(),
               }
            )}
          ></i> */}
        </div>

        <div className='price-section px-0 mt-2'>
          <h3 className='d-inline fs-6'>
            {/* ₹ {vehicleData.upcoming_price[0].toLocaleString()} -{' '}
            {vehicleData.upcoming_price[1].toLocaleString()} */}
            ₹ {totalPrice1} - {totalPrice2}
            <div className='price-desc fw-medium'>Estimated Price</div>
          </h3>
          {/* <div className='price-desc mt-1'>
            On Road Price in, Ahmedabad
          </div> */}
        </div>
        <div className='mt-2 ps-0'>
          Launch date :{' '}
          <span className='small-lbl'>{vehicleData.launch_date}</span>
        </div>
        {/* <span className='small-lbl p-0 mt-2 text-dark'>Critiqeu rating</span>
        <Progress percent={50} showInfo={false} strokeColor={'#2ecc71'} />
        <span className='small-lbl p-0 mt-1 text-dark'>User rating</span>
        <Progress percent={50} showInfo={false} strokeColor={'#fcbf00'} /> */}
      </div>
      {renderShareModal()}
    </div>
  );
}

